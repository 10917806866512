<template>
  <div class="submit-form" style="max-width: 400px">
    <div>
      <h1>{{ $t('register.register') }}</h1>
      <b-row align-h="center" style="margin-bottom: 5px">
        <b-col class="text-left" sm="12">
          <label :for="'firstName'">{{ $t('register.firstName') }}:</label>
        </b-col>
        <b-col sm="12">
          <b-form-input :id="'firstName'"
                        :type="'text'"
                        v-model="firstName"
                        aria-describedby="firstName-feedback">
          </b-form-input>
        </b-col>
      </b-row>
      <b-row align-h="center" style="margin-bottom: 5px">
        <b-col class="text-left" sm="12">
          <label :for="'lastName'">{{ $t('register.lastName') }}:</label>
        </b-col>
        <b-col sm="12">
          <b-form-input :id="'lastName'"
                        :type="'text'"
                        v-model="lastName"
                        aria-describedby="lastName-feedback">
          </b-form-input>
        </b-col>
      </b-row>
      <b-row align-h="center" style="margin-bottom: 5px">
        <b-col class="text-left" sm="12">
          <label :for="'street'">{{ $t('register.street') }}:</label>
        </b-col>
        <b-col sm="12">
          <b-form-input :id="'street'"
                        :type="'text'"
                        v-model="user.street"
                        aria-describedby="street-feedback">
          </b-form-input>
        </b-col>
      </b-row>
      <b-row align-h="center" style="margin-bottom: 5px">
        <b-col class="text-left" sm="12">
          <label :for="'zip'">{{ $t('register.postcode') }}, </label>
          <label :for="'city'">{{ $t('city.') }}:</label>
        </b-col>
        <b-col sm="4">
          <b-form-input :id="'zip'"
                        :type="'text'"
                        v-model="user.zip"
                        aria-describedby="zip-feedback">
          </b-form-input>
        </b-col>
        <b-col sm="8">
          <b-form-input :id="'city'"
                        :type="'text'"
                        v-model="user.city"
                        aria-describedby="city-feedback">
          </b-form-input>
        </b-col>
      </b-row>
      <b-row align-h="center" style="margin-bottom: 5px">
        <b-col class="text-left" sm="12">
          <label :for="'country'">{{ $t('register.country') }}:</label>
        </b-col>
        <b-col sm="12">
          <b-form-select :id="'country'" v-model="user.country" :options="countries"></b-form-select>
        </b-col>
      </b-row>
      <b-row align-h="center" style="margin-bottom: 5px; margin-top: 5px">
        <b-col class="text-left" sm="12">
          <label :for="'email'">E-Mail:</label>
        </b-col>
        <b-col sm="12">
          <b-form-input
              :state="emailState"
              :type="'email'"
              :id="'email'"
              required
              v-model="user.eMail"
              name="email"
              aria-describedby="email-feedback"
          />
          <b-form-invalid-feedback id="email-feedback">
            {{mailErrorText}}
          </b-form-invalid-feedback>
        </b-col>
      </b-row>
      <b-row align-h="center" style="margin-bottom: 5px">
        <b-col class="text-left" sm="12">
          <label :for="'password'">Password:</label>
        </b-col>
        <b-col sm="12">
          <b-form-input :state="passwordState"
                        required
                        :id="'password'"
                        :type="'password'"
                        v-model="user.password"
                        aria-describedby="password-feedback">
          </b-form-input>
          <b-form-invalid-feedback id="password-feedback">
            {{ $t('pleaseEnterPassword') }}
          </b-form-invalid-feedback>
        </b-col>
      </b-row>
      <b-row align-h="center" style="margin-bottom: 5px">
        <b-col class="text-left" sm="12">
          <label :for="'passwordCheck'">{{ $t('register.confirmPassword') }}:</label>
        </b-col>
        <b-col sm="12">
          <b-form-input :state="passwordCheckState"
                        required
                        :id="'passwordCheck'"
                        :type="'password'"
                        v-model="passwordCheck"
                        aria-describedby="passwordCheck-feedback">
          </b-form-input>
          <b-form-invalid-feedback id="passwordCheck-feedback">
            {{ $t('register.passwordDontMatch') }}
          </b-form-invalid-feedback>
        </b-col>
      </b-row>
      <b-row align-h="center" style="margin-bottom: 5px">
        <b-col class="text-left" sm="12">
          <b-form-checkbox
              id="termsCheck"
              v-model="acceptTerms"
              name="termsCheck"
              value="true"
              unchecked-value="false"
              :state="termsState"
          >
          <b-link style="color: #2c3e50" href="#/termsAndConditions" >{{ $t('register.acceptConditions') }}</b-link>
          </b-form-checkbox>
          <b-form-invalid-feedback class="text-left" :state="termsState" id="termsCheck-feedback">
            {{ $t('acceptCon') }}
          </b-form-invalid-feedback>
        </b-col>
      </b-row>
      <b-row align-h="center" style="margin-bottom: 5px">
        <b-col class="text-left" sm="12">
          <b-form-checkbox
              id="checkbox-1"
              v-model="user.newsletter"
              name="checkbox-1"
              value="1"
              unchecked-value="0"
          >
            {{ $t('register.subscribeNewsletter') }}
          </b-form-checkbox>
        </b-col>
      </b-row>
      <button @click="saveUser" class="btn btn-success mt-3">{{ $t('register.register') }}</button>
    </div>
  </div>
</template>

<script>
import UserDataService from "../services/UserDataService";

const countries = require('i18n-iso-countries')
countries.registerLocale(require('i18n-iso-countries/langs/en.json'))

export default {
  name: "register",
  data() {
    return {
      user: {
        id: null,
        eMail: "",
        password: "",
        name: "",
        zip: "",
        street: "",
        city: "",
        country: "IT",
        newsletter: 0,
      },
      firstName: '',
      lastName: '',
      passwordCheck: '',
      submitted: false,
      acceptTerms: false,
      mailErrorText: this.$i18n.messages[this.$i18n.locale].emailTaken,
      mailError: false,
      registerSuccess: false,
    };
  },
  computed: {
    emailState() {
      return this.user.eMail.length <= 0 && this.submitted || this.mailError ? false : null
    },
    passwordState() {
      return this.user.password.length <= 0 && this.submitted ? false : null
    },
    passwordCheckState() {
      return this.user.password === this.passwordCheck ? null : false
    },
    termsState() {
      console.log("TERMS");
      console.log(this.acceptTerms);
      if (this.acceptTerms === false && this.submitted) {
        return false;
      } else if (this.acceptTerms === true && this.submitted) {
        return true
      } else {
        return null
      }
    },
    countries() {
      const list = countries.getNames('en', {select: 'official'})
      console.log(list);
      return Object.keys(list).map((key) => ({value: key, text: list[key]}))
    }
  },
  methods: {
    saveUser() {
      console.log("CLICK!");
      this.submitted = true;
      console.log(this.passwordState);
      console.log(this.emailState);
      console.log(this.termsState);
      this.mailError = false;
      if(this.user.eMail.length <= 0){
        this.mailErrorText = this.$i18n.messages[this.$i18n.locale].pleaseEnterEmail;
      }
      if (this.passwordState == null && this.emailState == null && (this.termsState == true || this.termsState == null) && this.submitted) {
        let data = {
          email: this.user.eMail,
          password: this.user.password,
          name: this.firstName + ' ' + this.lastName,
          street: this.user.street,
          zip: this.user.zip,
          city: this.user.city,
          country: this.user.country,
          newsletter: this.user.newsletter,
          lang: this.$i18n.locale
        };
        console.log(data);
        UserDataService.create(data)
            .then(response => {
              /*this.user.id = response.data.data.id;
              console.log(response.data);
              console.log(response.data.data.id);
              let newUser = {
                id: response.data.data.id,
                email: this.user.eMail,
                password: this.user.password,
                name: this.firstName + ' ' + this.lastName,
                street: this.user.street,
                zip: this.user.zip,
                city: this.user.city,
                country: this.user.country,
                newsletter: this.user.newsletter,
              }
              this.submitted = false;
              this.$store.commit('SET_ACCESS_TOKEN', {accessToken: response.data.accessToken});
              this.$store.commit('SET_ROLE', {role: response.data.role});
              this.$store.commit('SET_USER', {user: newUser});
              localStorage.accessToken = response.data.accessToken;
              localStorage.role = response.data.role;
              localStorage.user = JSON.stringify(newUser);
              this.mailError = false;*/
              if(response.data.data){
                let params = {
                  msg: this.$i18n.messages[this.$i18n.locale].signUpSuccess
                }
                this.$router.push({name: 'home', params});
              }
            })
            .catch(e => {
              console.log(e);
              console.log(e.response);
              if (e.response.data.error === 'The email is already taken') {
                this.mailError = true;
                this.mailErrorText = this.$i18n.messages[this.$i18n.locale].emailTaken;
              }
            });
      }
    },
  },
  mounted() {
    console.log("MOUNTED");
  }
};
</script>

<style>
.submit-form {
  max-width: 300px;
  margin: auto;
}
</style>
